import {
    arraysEqual, DirectionalHint, ICommandBarItemProps, IconButton, IContextualMenuItem,
    IDialogContentProps, MessageBar, MessageBarType, PrimaryButton, Selection
} from 'office-ui-fabric-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { EntityType } from '../../entities/common';
import { ApplicationState } from '../../store';
import { actionCreators as roadmapActionCreators } from '../../store/RoadmapsListStore';
import { UserState } from "../../store/User";
import { canCreate, canUpdate, CommonOperations, contains } from "../../store/permissions";
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import { IEntitiesScreenView, IHeaderProps } from '../common/EntitiesScreenHeader';
import EntitiesScreenBuilder from '../common/EntitiesScreen';
import Spinner from '../common/Spinner';
import RoadmapCreation from "./RoadmapCreation";
import * as ViewsStore from '../../store/views';
import { bindActionCreators } from 'redux';
import * as Metadata from "../../entities/Metadata";
import { IDetailsProps, IListProps } from '../common/extensibleEntity/EntityDetailsList';
import ListSubView from '../views/list/ListSubView';
import RemoveDialog from '../common/RemoveDialog';
import { IDeletionResult } from '../../store/services/storeHelper';
import { SortService } from '../../services/SortService';
import EntitiesCardList from '../common/EntitiesCardList';
import { default as RoadmapCard, RoadmapCardState } from '../views/card/RoadmapCard';
import { Roadmap } from '../../store/roadmap/common';
import { FilterHelper, RoadmapFilterValue } from '../../store/roadmap/filters';
import * as FiltersStore from "../../store/filters";
import { default as GenericEntitiesFilter } from '../common/EntitiesFilter';
import SharePanel from '../common/SharePanel';
import { notUndefined } from '../utils/common';
import EditListSubView from '../views/list/EditListSubView';
import { Sorter } from '../utils/HierarchyManager';
import { LayoutsState, _layoutFakeFieldName } from '../../store/layouts';
import { LayoutService } from '../utils/LayoutService';
import * as Notifications from "../../store/NotificationsStore";
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import ValidateRemoveDialog from '../common/ValidateRemoveDialog';
import { rendersBuilder, validators } from '../field/Fields';
import { IWithSearch, withSearch } from '../common/withSearch';

type ActionProps = {
    roadmapsActions: typeof roadmapActionCreators;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    notificationsActions: typeof Notifications.actionCreators;
};

type StateProps = {
    fields: Metadata.Field[];
    fakeFields: Metadata.Field[];
    layouts: LayoutsState;
    views?: ViewsStore.IViewsState;
    user: UserState;
    roadmaps: Roadmap[];
    isLoading: boolean;
    isListLoading: boolean;
    deletionResult?: IDeletionResult[];
    activeFilter?: Metadata.IFilter<RoadmapFilterValue>;
    autoFilterId: string;
    preFilterId?: string;
};

export type Props = StateProps & ActionProps & RouteComponentProps<{}> & IWithSearch;

type State = {
    selectedItems: Roadmap[];
    roadmapsToRemove: Roadmap[];
    layoutToApply?: Metadata.Layout;

    isCreate: boolean;
    canManageConfiguration: boolean;
    canManage: boolean;
    canEdit: boolean;

    isListViewEdit: boolean;

    preFilter: Metadata.PreFilter<Roadmap>;
    entityFilterHelper: Metadata.IEntityFilterHelper<Roadmap>;

    share?: Roadmap;
}

const EntitiesFilter = GenericEntitiesFilter<Roadmap>();
const EntitiesScreen = EntitiesScreenBuilder<Roadmap>();

class RoadmapsList extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);

        const preFilterOptions: Metadata.PreFilterOption<Roadmap>[] = [
            { key: "only-my", name: "Only Mine", predicate: _ => !!_.attributes.Manager && !!_.attributes.Manager.find(m => m.id === props.user.id) }
        ];

        this.state = {
            selectedItems: [],
            roadmapsToRemove: [],
            isCreate: false,
            canManageConfiguration: contains(props.user.permissions.common, CommonOperations.ConfigurationManage),
            canManage: true,
            isListViewEdit: false,
            canEdit: this._canEdit(props),
            preFilter: Metadata.PreFilter.create(preFilterOptions,
                active => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id, active?.key)),
            entityFilterHelper: new FilterHelper({
                fields: this.props.fields,
                layouts: props.layouts.allIds.map(_ => props.layouts.byId[_])
            })
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItems: this._selection.getSelection() as Roadmap[] });
            }
        });
    }

    componentWillMount() {
        this.props.roadmapsActions.requestRoadmaps();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!arraysEqual(this.props.fields, nextProps.fields)) {
            const entityFilterHelper = new FilterHelper({
                fields: nextProps.fields,
                layouts: nextProps.layouts.allIds.map(_ => nextProps.layouts.byId[_])
            });
            this.setState({ entityFilterHelper });
        }
    }

    private _clearPreFilter = () => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id);

    render() {
        const { isCreate, roadmapsToRemove } = this.state;
        if (this.props.isLoading || this.props.isListLoading) {
            return <Spinner />;
        }

        const { roadmaps, deletionResult } = this.props;
        return <>
            {
                roadmaps.length === 0
                    ? <EmptyEntitiesScreen
                        className="roadmap"
                        title="roadmaps"
                        description="Prioritize initiatives across portfolios, programs and projects and empower your team to make the right strategic decisions.">
                        <PrimaryButton disabled={!canCreate(this.props.user.permissions.roadmap)} text="Create Roadmap" onClick={() => this.setState({ isCreate: true })} />
                    </EmptyEntitiesScreen>
                    : <EntitiesScreen
                        title="roadmaps"
                        clearPreFilter={this._clearPreFilter}
                        selection={this._selection}
                        canManageConfiguration={this.state.canManageConfiguration}
                        activeViewType={this.props.views!.activeViewType}
                        viewChanged={this._viewChanged}
                        fields={this.props.fields}
                        fakeFields={this.props.fakeFields}
                        entities={this.applyFilter(roadmaps)}
                        views={[this.getCardView(), this.getListView()]}
                        filter={{
                            activeFilter: this.props.activeFilter,
                            autoFilterId: this.props.autoFilterId,
                            getAttributeValue: this.getAttributeValue,
                            onFilterRender: this._renderFilter
                        }}
                        headerProps={this.getHeaderProps()}
                        router={{
                            history: this.props.history,
                            match: this.props.match,
                            location: this.props.location
                        }}
                        baseUrl="/roadmaps"
                        canEdit={this.state.canManage}
                        selectedItemIds={this.state.selectedItems.map(_ => _.id)}
                        search={this.props.search}
                    />
            }
            {isCreate && <RoadmapCreation onDismiss={() => this.setState({ isCreate: false })} openOnComplete />}
            {this.state.share && <SharePanel
                key="share-panel"
                entity={this.state.share}
                entityType={EntityType.Roadmap}
                layouts={this.props.layouts}
                onDismiss={() => this.setState({ share: undefined })}
                hideCollaborate />}
            {!!roadmapsToRemove.length &&
                <ValidateRemoveDialog
                    entitiesTitle={'roadmaps'}
                    itemsToRemove={roadmapsToRemove}
                    onClose={() => this.setState({ roadmapsToRemove: [] })}
                    onComplete={() => {
                        this.props.roadmapsActions.removeRoadmap(roadmapsToRemove.filter(_ => _.isEditable).map(_ => _.id));
                    }}
                    dialogContentProps={this._getRemoveDialogContent(roadmapsToRemove)} />}
            {deletionResult && <RemoveDialog
                onClose={() => this.props.roadmapsActions.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && deletionResult.some(_ => !_.isDeleted) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    Failed to delete the following roadmaps:
                    <ul>
                        {deletionResult.filter(_ => !_.isDeleted).map(_ => <li key={_.id}>{_.name}</li>)}
                    </ul>
                    Please check if you have necessary permissions.
                </MessageBar>}
            </RemoveDialog>}
            {this.state.layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => {
                    this.props.roadmapsActions.applyLayoutMany(this.state.selectedItems.map(_ => _.id), this.state.layoutToApply!.id);
                    this.props.notificationsActions.pushNotification({
                        message: `Layout '${this.state.layoutToApply!.name}' applied`,
                        type: Notifications.NotificationType.Info
                    });
                }}
                onDismiss={() => this.setState({ layoutToApply: undefined })}
                entityType={EntityType.Roadmap}
                layoutName={this.state.layoutToApply!.name}
                count={this.state.selectedItems.length}
            />}
        </>;
    }

    private applyFilter = (_: Roadmap[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isFieldFake: (field: Metadata.Field) => boolean = _ => this.props.fakeFields.some(f => f.id === _.id);

    private _sorter: Sorter<Roadmap> = (orderBy) => {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.fields);

        const extractor = (item: Roadmap, field: Metadata.Field) => {
            if (field.name === _layoutFakeFieldName) {
                return item.layoutId ? this.props.layouts.byId[item.layoutId]?.name : null;
            }
            return SortService.getFieldValueBaseImpl(field, item, this._isFieldFake)
        }

        const fieldsMap = Metadata.toMap(fields, this._isFieldFake);
        return (a, b) => SortService.getComparer(fieldsMap, orderBy, this._isFieldFake, extractor)(a, b);
    }

    private _isItemVisible = (item: Roadmap): boolean => {
        const { preFilter } = this.state;
        const { activeFilter, search } = this.props;

        if (Metadata.PreFilter.isItemVisible(preFilter, item, this.props.preFilterId)) {
            return false;
        }

        if (search && !search.isItemVisible(item)) {
            return false;
        }

        if (activeFilter) {
            const filterValue = activeFilter.value;
            const allAttributes = this.state.entityFilterHelper.getFilterAttributes(this.props.fields);

            for (const type in filterValue) {
                if (!this.state.entityFilterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type))) {
                    return false;
                }
            }
        }

        return true;
    }

    private getAttributeValue = (attrType: keyof RoadmapFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _renderFilter = (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => (
        <EntitiesFilter
            canManageConfiguration={this.state.canManageConfiguration}
            preFilter={this.state.preFilter}
            preFilterId={this.props.preFilterId}
            activeFilter={this.props.activeFilter}
            onActiveFilterChanged={this._onActiveFilterChanged}
            onFilterChanged={this.props.filtersActions.updateFilter}
            isFilterPanelOpen={isFilterPanelOpen}
            toggleFilterPanel={toggleFilterPanel}
            entityType={EntityType.Roadmap}
            entityFilterHelper={this.state.entityFilterHelper}
        />
    );

    private _onActiveFilterChanged = (id?: string) => {
        this.props.filtersActions.setActiveFilter(id, this.props.preFilterId);
    }

    private _getRemoveDialogContent(toRemove: Roadmap[]) {
        if (toRemove.length === 1) {
            return {
                title: "Delete roadmap",
                subText: `Are you sure you want to delete roadmap "${toRemove[0].attributes.Name}"?`
            }
        }

        return {
            title: "Delete roadmaps",
            subText: `Are you sure you want to delete selected roadmaps (${toRemove.length} items)?`
        }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1) {
            return deletionResult[0].isDeleted
                ? {
                    title: "Roadmap deletion is complete",
                    subText: `Roadmap "${deletionResult[0].name}" was deleted successfully.`
                }
                : {
                    title: "Unable to delete roadmap",
                    subText: deletionResult[0].message
                }
        }

        const notDeleted = deletionResult.filter(_ => !_.isDeleted);
        if (notDeleted.length) {
            return {
                title: "The deletion was completed with warnings",
                isMultiline: true,
                subText: notDeleted.map(_ => _.message).join(".\r\n")
            }
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return {
            title: "Roadmaps deletion is completed",
            subText: `Selected roadmaps (${deleted.length} items) were deleted successfully.`
        }
    }

    private _viewChanged = (view: IEntitiesScreenView<Roadmap>) => {
        this.setState({ selectedItems: [] });
        this.props.viewsActions.setActiveView(view.url);
    }

    private getCardView(): IEntitiesScreenView<Roadmap> {
        const { card } = this.props.views!;
        const fieldsMap = Metadata.toMap(this.props.fields);
        return {
            icon: 'PPMXCardView',
            url: 'card',
            subViews: card.subViews,
            sortBy: card.sortBy,
            onSortChange: this.props.viewsActions.changeCardViewSort,
            activeSubViewId: card.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setCardActiveSubView,
            render: (key: string, activeSubView: Metadata.ICardSubView, entities: Roadmap[]) => {
                const comparer = SortService.getComparer(fieldsMap, card.sortBy.active.orderBy);
                return <EntitiesCardList
                    key={key}
                    entities={[...entities].sort(comparer)}
                    onCardRender={
                        (entity: Roadmap,
                            cardState: RoadmapCardState | undefined,
                            persistCardState: (newCardState: Partial<RoadmapCardState>) => void) => <RoadmapCard
                                key={entity.id}
                                entity={entity}
                                fields={fieldsMap}
                                onMenuRender={this._renderMenu}
                                state={cardState}
                                onChangeState={persistCardState}
                            />}
                    cardParams={{ width: 350, height: 202 }} />;
            }
        }
    }

    private _renderMenu = (entity: Roadmap) => {
        const items: IContextualMenuItem[] = [
            entity.isEditable ? {
                key: 'share',
                name: 'Share',
                iconProps: { iconName: 'Share' },
                onClick: () => this.setState({ share: entity })
            } : undefined,
            {
                key: 'edit',
                name: entity.isEditable ? 'Edit' : 'View',
                iconProps: { iconName: entity.isEditable ? 'Edit' : 'View' },
                onClick: () => this.props.history.push(`/roadmap/${entity.id}`)
            },
            entity.isEditable ? {
                key: 'delete',
                name: 'Delete',
                iconProps: { iconName: "Delete", style: { color: 'red' } },
                disabled: this.props.isLoading,
                style: {
                    color: (this.props.isLoading ? 'initial' : 'red'),
                    backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset')
                },
                onClick: () => this.setState({ roadmapsToRemove: [entity] })
            } : undefined
        ].filter(notUndefined);

        return <div className="menu">
            <IconButton
                menuIconProps={{ iconName: 'PPMXMore' }}
                menuProps={{
                    directionalHint: DirectionalHint.bottomRightEdge,
                    items: items
                }}
            />
        </div>;
    }

    private onInlineEditComplete = (field: Metadata.Field, item: Roadmap, value: any) => {
        this.props.roadmapsActions.updateRoadmapAttributes(item.id, { [field.name]: value });
    }

    private getListView = (): IEntitiesScreenView<Roadmap> => {
        const { list } = this.props.views!;
        const allfields = this.props.fields.concat(this.props.fakeFields);
        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: 'list',
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onEditSubViewClick: id => {
                if (list.activeSubViewId !== id) {
                    this.props.history.push(`/roadmaps/list/${id}`)
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/roadmaps/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            },
            render: (key: string, activeSubView: Metadata.IListSubView, entities: Roadmap[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: this._renderMenu,
                    inlineEditProps: this._canEdit(this.props) ? {
                        onInlineEditComplete: this.onInlineEditComplete,
                        readonlyFields: () => this.props.fakeFields.map(_ => _.name),
                        customFieldValidatorBuilder: validators,
                        uiControlElementsCustomRender: rendersBuilder()
                    } : undefined
                };

                return [<ListSubView
                    key="list-view"
                    type="Details"
                    entities={entities}
                    selection={this._selection}
                    entityType={EntityType.Roadmap}
                    fields={allfields}
                    isFieldFake={this._isFieldFake}
                    sorter={this._sorter}
                    sort={list.sortBy}
                    onSortChange={this.props.viewsActions.changeListViewSort}
                    view={activeSubView}
                    listProps={listProps}
                    onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                    selectionModeItems={this._buildSelectedModeMenuItems()}
                />,
                this.state.isListViewEdit
                    ? <EditListSubView key="create-details-view"
                        subView={activeSubView}
                        entityType={EntityType.Roadmap}
                        selectedByDefault={this.props.views!.list.selectedByDefault}
                        fields={allfields}
                        onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                        onSave={() => {
                            this.props.viewsActions.saveListSubView(activeSubView, 'roadmaps');
                            this.setState({ isListViewEdit: false });
                        }}
                        onCopy={() => this._onCopyListSubView(activeSubView)}
                        onDismiss={() => this.setState({ isListViewEdit: false })} />
                    : <span key="no-edit"></span>];
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/roadmaps/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'roadmaps', undefined, view.id);
    }

    private _buildSelectedModeMenuItems = (): ICommandBarItemProps[] => {
        const { selectedItems, canEdit } = this.state;
        if (!canEdit) {
            return [];
        }

        const layoutMenuItem = LayoutService.buildApplyLayoutMenuItem(this.props.layouts, (layout: Metadata.Layout) => {
            this.setState({ layoutToApply: layout });
        });

        return [
            {
                ...layoutMenuItem,
                disabled: !!selectedItems.find(_ => !_.canConfigure),
            },
            {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Roadmap),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                disabled: !this.state.canManage,
                onClick: () => this.setState({ roadmapsToRemove: selectedItems }),
            }
        ];
    };

    private getHeaderProps = (): IHeaderProps => {
        return {
            entityType: EntityType.Roadmap,
            createEntityTypes: [EntityType.Portfolio, EntityType.Program, EntityType.Project, EntityType.Roadmap, EntityType.Resource, EntityType.PrivateProject],
            importEntityTypes: []
        };
    }

    private _canEdit(props: Props) {
        return canCreate(props.user.permissions.roadmap) || canUpdate(props.user.permissions.roadmap) || props.roadmaps.some(_ => _.isEditable);
    }

}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps => {
    const fields = state.fields[EntityType.Roadmap];
    const filters = FiltersStore.getFilter(state.filters, EntityType.Roadmap);
    const autoFilterId = Metadata.Filter.getAutoFilterId(filters.all) ?? filters.all[0].id;
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        fakeFields: state.views[EntityType.Roadmap].list.fakeFields,
        layouts: state.layouts[EntityType.Roadmap],
        views: state.views[EntityType.Roadmap],
        user: state.user,
        roadmaps: state.roadmaps.allIds.map(_ => state.roadmaps.byId[_]),
        isLoading: state.roadmaps.isLoading,
        isListLoading: state.roadmaps.isListLoading,
        deletionResult: state.roadmaps.deletionResult,
        activeFilter: filters.active.filter,
        autoFilterId: autoFilterId,
        preFilterId: filters.active.preFilterId
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        roadmapsActions: bindActionCreators(roadmapActionCreators, dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Roadmap), dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Roadmap), dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(withSearch(RoadmapsList));