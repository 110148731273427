import { get, post, remove } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { push, RouterAction } from 'react-router-redux';
import { IEntityStore, StoreHelper, IDeletionResult } from './services/storeHelper';
import { StatusCategory, Dictionary, EntityType, IEntityInfo, IUserInfo, IWithStartFinishDates, statusCategoryMap, IExtensibleEntity } from "../entities/common";
import { defaultCatch } from "./utils";
import { IDropdownOption } from 'office-ui-fabric-react';
import { ActionsBuilder, nameof, namesof } from './services/metadataService';
import * as analytics from '../analytics';
import { Field } from '../entities/Metadata';
import { notUndefined } from '../components/utils/common';

export const TITLE_FIELD_ID = "44549f18-96b2-4cd9-bb08-02576d486d70";

export const MaxValueLimit = 10000000000;
export enum OKRState {
    Open = 0,
    Closed = 1,
    Archived = 2
}
export interface ObjectiveStateConfig { title: string, cssClassName: string }
export const objectiveStatesMap: { [i: number]: ObjectiveStateConfig } =
{
    [OKRState.Open]: {
        title: "Open",
        cssClassName: "Open",
    },
    [OKRState.Closed]: {
        title: "Closed",
        cssClassName: "Closed"
    },
    [OKRState.Archived]: {
        title: "Archived",
        cssClassName: "Archived"
    }
}

export enum OKRValueType {
    Number = 0,
    Percent = 1,
    Money = 2,
    Flag = 3
}

export enum OKRDirection {
    Growth = 0,
    Reduction = 1,
    AboveThreshold = 2,
    BelowThreshold = 3
}

export enum ObjectiveCalculationType {
    Manual = 0,
    Calculated = 1,
    KR_Rollup = 2,
}

export interface IObjectiveInfo extends IEntityInfo { }

export interface IObjectiveAttrs extends IWithStartFinishDates {
    State: OKRState;
    Name: string;
    Parent: IObjectiveInfo | null;
    Description: string;
    Scope: string[];
    Manager: IUserInfo[];
    CalculationType: ObjectiveCalculationType;
    Progress: number;
    Status: string;
    ValueType: OKRValueType;
    Direction: OKRDirection;
    TargetValue: number;
    StartValue: number;
    CurrentValue: number;
    Tags?: string[];
}

export interface Objective extends IExtensibleEntity {
    isEditable: boolean;
    canConfigure: boolean;
    parentState?: OKRState;
    attributes: IObjectiveAttrs & Dictionary<any>;
    keyResults: KeyResult[];
}

export interface KeyResult {
    id: string;
    name: string;
    state: OKRState;
    calculationType: ObjectiveCalculationType;
    valueType: OKRValueType;
    direction: OKRDirection;
    targetValue: number;
    startValue: number;
    currentValue: number;
    progress: number;
    status: StatusCategory;
    projectIds: string[];
    description: string;
}

export const keyResultFieldNames = namesof<IObjectiveAttrs>([
    "Name", "State", "CalculationType", "ValueType", "Direction", "TargetValue", "StartValue", "CurrentValue", "Progress", "Status", "Description"
]);

export type KeyResultWrapper = { entityType: 'keyresult', objectiveId: string; keyResult: KeyResult; };
export type ObjectiveOrKeyResult = Objective & ({ entityType?: undefined } | KeyResultWrapper);
export function isObjective(item: ObjectiveOrKeyResult): boolean { return item.entityType !== 'keyresult'; }
export const extractKeyResult = (item: ObjectiveOrKeyResult): KeyResult | undefined => !isObjective(item) ? (item as KeyResultWrapper).keyResult : undefined;

export interface ObjectivesState extends IEntityStore<Objective> {
    isLoading: boolean;
    isPartialLoading: boolean;
    isListUpdating: boolean;
    deletionResult?: IDeletionResult[];
}

export const OKRValueTypeOptions: IDropdownOption[] = [
    { key: OKRValueType.Number, text: 'Number' },
    { key: OKRValueType.Percent, text: 'Percent' },
    { key: OKRValueType.Money, text: 'Money' },
    { key: OKRValueType.Flag, text: 'Flag' },
];

export const OKRDirectionOptions: IDropdownOption[] = [
    { key: OKRDirection.Growth, text: 'Growth' },
    { key: OKRDirection.Reduction, text: 'Reduction' },
    { key: OKRDirection.AboveThreshold, text: 'Above Threshold' },
    { key: OKRDirection.BelowThreshold, text: 'Below Threshold' },
];

export const OKRCalculationTypeOptions: IDropdownOption[] = [
    { key: ObjectiveCalculationType.Calculated, text: 'Calculated' },
    { key: ObjectiveCalculationType.Manual, text: 'Manual' },
];

const unloadedState: ObjectivesState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListUpdating: false,
    isPartialLoading: false
};

interface ObjectivesLoadAction {
    type: 'OBJECTIVES_LOAD';
    partial?: boolean;
}

export interface ObjectivesLoadedAction {
    type: 'OBJECTIVES_LOADED';
    objectives: Objective[];
    partial?: boolean;
}

interface ObjectiveLoadAction {
    type: 'OBJECTIVE_LOAD';
    id: string;
}
interface ListUpdateStateChangedAction {
    type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED';
    isListUpdating: boolean;
}
interface ObjectiveLoadedAction {
    type: 'OBJECTIVE_LOADED';
    objective: Objective;
}

interface ReceivedDeleteObjectivesResultAction {
    type: 'RECEIVED_REMOVE_OBJECTIVE_RESULT';
    deletionResult?: IDeletionResult[];
    objectives?: Objective[];
}

interface CreateObjectiveSuccessAction {
    type: 'CREATE_OBJECTIVE_SUCCESS';
    objectives: Objective[];
}

type KnownAction = ObjectivesLoadAction
    | ObjectivesLoadedAction
    | ObjectiveLoadAction
    | ListUpdateStateChangedAction
    | ObjectiveLoadedAction
    | ReceivedDeleteObjectivesResultAction
    | CreateObjectiveSuccessAction;

export const defaultActionCreators = {
    // Objective Management
    loadObjectives: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Objective[]>(`api/objective`)
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: 'OBJECTIVES_LOAD' });
    },
    loadSubObjectives: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Objective[]>(`api/objective/${id}/subobjectives`)
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: 'OBJECTIVES_LOAD', partial: true });
    },
    loadObjective: (objectiveId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Objective>(`api/objective/${objectiveId}`)
            .then(data => dispatch({ type: 'OBJECTIVE_LOADED', objective: data }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTIVE_LOAD', id: objectiveId }));
    },
    removeObjectives: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<any>(`api/objective/${ids[0]}`)
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_OBJECTIVE_RESULT", deletionResult: data.deletionResult, objectives: data.objectives });
                    if (redirectBack) {
                        dispatch(push('/objectives'));
                    }
                })
                .catch(defaultCatch(dispatch))
            : post<any>(`api/objective/bulkDelete`, { ids })
                .then(data => dispatch({ type: "RECEIVED_REMOVE_OBJECTIVE_RESULT", deletionResult: data.deletionResult, objectives: data.objectives }))
                .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    loadObjectivesByIds: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'OBJECTIVES_LOAD', partial: true });

        post<Objective[]>(`api/objective/getbyids`, { ids: ids })
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_OBJECTIVE_RESULT" });
    },
    createObjective: (objective: { attributes: IObjectiveAttrs }, parentId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        analytics.trackCreate(getState().user, { 
            itemTitle: objective.attributes.Name, 
            itemType: EntityType.Objective,
            parentType: objective.attributes.Parent ? EntityType.Objective : undefined
        });

        post<Objective[]>(`api/objective${parentId ? `/${parentId}` : ''}`, objective)
            .then(data => dispatch(<CreateObjectiveSuccessAction>{ type: "CREATE_OBJECTIVE_SUCCESS", objectives: data }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    cloneObjective: (objectiveId: string, navigate?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<{ objectiveId: string; objectives: Objective[] }>(`api/objective/${objectiveId}/clone`, {})
            .then(data => {
                dispatch(<CreateObjectiveSuccessAction>{ type: 'CREATE_OBJECTIVE_SUCCESS', objectives: data.objectives });
                navigate && dispatch(push(`/objective/${data.objectiveId}`, {}));
            })
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    importObjectives: (objectiveId: string, ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/import`, { ids })
            .then(data => dispatch(<CreateObjectiveSuccessAction>{ type: 'CREATE_OBJECTIVE_SUCCESS', objectives: data }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    moveObjective: (objectiveId: string, parent: IObjectiveInfo | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/move`, { parent })
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    updateObjectiveState: (objectiveId: string, state: OKRState): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/state`, { state })
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    updateObjectiveAttributes: (objectiveId: string, newAttributeValues: Dictionary<any>):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Objective[]>(`api/objective/${objectiveId}/attributes`, newAttributeValues)
                .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
                .catch(defaultCatch(dispatch));
        },
    updateUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Objective),
    updateUIControlOnClient: ActionsBuilder.buildLayoutUpdateUIControlOnClient(EntityType.Objective),
    updateSections: ActionsBuilder.buildLayoutUpdateSections(EntityType.Objective),
    updateSectionsOnClient: ActionsBuilder.buildLayoutUpdateSectionsOnClient(EntityType.Objective),
    saveKeyResult: (objectiveId: string, keyResult: KeyResult): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/keyresult`, keyResult)
            .then(data => dispatch({ type: "OBJECTIVES_LOADED", objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    cloneKeyResults: (objectiveId: string, keyResultsIds: string[]): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/keyresult/clone`, { ids: keyResultsIds })
            .then(data => dispatch({ type: "OBJECTIVES_LOADED", objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    importKeyResults: (objectiveId: string, ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/keyresult/import`, { ids })
            .then(data => dispatch({ type: "OBJECTIVES_LOADED", objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    moveKeyResult: (keyResultId: string, fromObjectiveId: string, toObjectiveId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${fromObjectiveId}/keyresult/${keyResultId}/move`, { toObjectiveId })
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    removeKeyResults: (objectiveId: string, keyResultsIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Objective[]>(`api/objective/${objectiveId}/keyresult`, { ids: keyResultsIds })
            .then(data => dispatch({ type: "OBJECTIVES_LOADED", objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'OBJECTVES_LIST_UPDATE_STATE_STARTED', isListUpdating: true }));
    },
    updateKeyResultState: (objectiveId: string, keyResultId: string, state: OKRState): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Objective[]>(`api/objective/${objectiveId}/keyresult/${keyResultId}/state`, { state })
            .then(data => dispatch({ type: 'OBJECTIVES_LOADED', objectives: data, partial: true }))
            .catch(defaultCatch(dispatch));
    }
};

export const defaultReducer: Reducer<ObjectivesState> = (state: ObjectivesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'OBJECTIVES_LOAD':
            return {
                ...state,
                isLoading: action.partial ? state.isLoading : true,
                isPartialLoading: action.partial ? true : state.isPartialLoading,
            };
        case 'OBJECTIVE_LOAD':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id === action.id ? state.activeEntity : undefined,
                isLoading: true
            };
        case 'OBJECTVES_LIST_UPDATE_STATE_STARTED':
            return {
                ...state,
                isListUpdating: action.isListUpdating
            };
        case 'OBJECTIVES_LOADED':
            const activeEntity = state.activeEntityId ? action.objectives.find(_ => _.id === state.activeEntityId) : undefined;
            return {
                ...state,
                ...(action.partial ? StoreHelper.union(state, action.objectives) : StoreHelper.create(action.objectives)),
                isLoading: action.partial ? state.isLoading : false,
                isPartialLoading: action.partial ? false : state.isPartialLoading,
                isListUpdating: false,
                activeEntity: activeEntity ?? state.activeEntity,
            };
        case 'OBJECTIVE_LOADED':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.objective),
                activeEntity: state.activeEntityId === action.objective.id ? action.objective : state.activeEntity,
                isLoading: false
            };
        case 'RECEIVED_REMOVE_OBJECTIVE_RESULT':
            {
                let newState = {
                    ...state,
                    ...StoreHelper.union(state, action.objectives ?? []),
                    isLoading: false,
                    isListUpdating: false,
                    deletionResult: action.deletionResult,
                    activeEntity: (state.activeEntityId ? action.objectives?.find(_ => _.id === state.activeEntityId) : undefined) ?? state.activeEntity,
                };
                if (action.deletionResult && action.deletionResult.length) {
                    action.deletionResult.forEach(result => {
                        if (result.isDeleted) {
                            newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                        }
                    });
                }
                return newState;
            }
        case 'CREATE_OBJECTIVE_SUCCESS': {
            const newState = {
                ...state,
                ...StoreHelper.union(state, action.objectives),
                isListUpdating: false
            };
            if (newState.activeEntityId) {
                const newActiveEntity = action.objectives.find(_ => _.id === newState.activeEntityId);
                if (newActiveEntity) {
                    newState.activeEntity = newActiveEntity;
                }
            }
            return newState;
        }
        default: const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};


export const reducer: Reducer<ObjectivesState> = (state: ObjectivesState, incomingAction: Action) => {
    state = state || unloadedState;
    return {
        ...defaultReducer(state, incomingAction),
    }
}

export const actionCreators = {
    ...defaultActionCreators,
};

export function ConvertToObjectiveOrKeyResult(
    objective: Objective,
    keyResult: KeyResult
): ObjectiveOrKeyResult {
    return {
        ...objective,
        id: keyResult.id,
        entityType: 'keyresult' as const,
        objectiveId: objective.id,
        keyResult: keyResult,
        attributes: {
            Name: keyResult.name,
            Progress: keyResult.progress,
            StartValue: keyResult.startValue,
            CalculationType: keyResult.calculationType,
            Direction: keyResult.direction,
            CurrentValue: keyResult.currentValue,
            TargetValue: keyResult.targetValue,
            ValueType: keyResult.valueType,
            State: keyResult.state,
            Status: statusCategoryMap[keyResult.status].title,
        } as IObjectiveAttrs
    };
}

export const getObjectiveOrKeyResultReadonlyFields = (entity: ObjectiveOrKeyResult, allFields: Field[]): string[] => {
    if (!entity.isEditable || entity.attributes.State !== OKRState.Open) {
        return allFields.map(_ => _.name);
    }

    return isObjective(entity)
        ? getObjectiveReadonlyFields(entity, allFields)
        : getKeyResultReadonlyFields((entity as KeyResultWrapper).keyResult, allFields);
}

export const getObjectiveReadonlyFields = (entity: Objective, allFields: Field[]): string[] =>
    !entity.isEditable || entity.attributes.State !== OKRState.Open
        ? allFields.map(_ => _.name)
        : [
            nameof<IObjectiveAttrs>("State"),
            entity.attributes.CalculationType === ObjectiveCalculationType.Manual ? undefined : nameof<IObjectiveAttrs>("Progress"),
            entity.attributes.CalculationType === ObjectiveCalculationType.KR_Rollup ? nameof<IObjectiveAttrs>("CurrentValue") : undefined,
            entity.attributes.CalculationType !== ObjectiveCalculationType.Calculated ? nameof<IObjectiveAttrs>("Direction") : undefined,
            ...entity.attributes.ValueType === OKRValueType.Flag ? namesof<IObjectiveAttrs>(["Direction", "StartValue", "TargetValue"]) : [],
        ].filter(notUndefined);

export const getKeyResultReadonlyFields = (entity: KeyResult, allFields: Field[]): string[] =>
    entity.state !== OKRState.Open
        ? allFields.map(_ => _.name)
        : [
            nameof<IObjectiveAttrs>("State"),
            entity.calculationType === ObjectiveCalculationType.Manual
                ? nameof<IObjectiveAttrs>("Direction")
                : nameof<IObjectiveAttrs>("Progress"),
            ...entity.valueType === OKRValueType.Flag ? namesof<IObjectiveAttrs>(["Direction", "StartValue", "TargetValue"]) : [],
            ...entity.projectIds.length ? namesof<IObjectiveAttrs>(["ValueType", "CalculationType", "Direction"]) : [],
            ...allFields.map(_ => _.name).filter(_ => !keyResultFieldNames.includes(_))
        ].filter(notUndefined);
