import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { SourceInfo } from "../../../../../entities/common";
import { Field } from "../../../../../entities/Metadata";
import RefreshProjectButton from "../../../../common/sectionsControl/uiControls/projectSummaryControl/RefreshProjectButton";

export default class SyncStatus extends React.Component<IListViewFieldColumn<ProjectInfo>> {
    public render() {
        const field = this.props.field;

        if (!field) {
            return <span>Invalid column configuration</span>;
        }

        return <RefreshProjectButton entity={this.props.entity} />;
    }
    
    public static getValue(entity: ProjectInfo, field: Field): number | undefined {
        const config = SourceInfo.getSyncStatusConfig(entity.isSyncable, entity.sourceInfos);
        return config?.order;
    }

    public static getSearchValue(entity: ProjectInfo, field: Field): string | undefined {
        const config = SourceInfo.getSyncStatusConfig(entity.isSyncable, entity.sourceInfos);
        return config?.label;
    }
}