import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { SourceTypeMap, SourceType_ } from "../../../../../store/ExternalEpmConnectStore";
import { Field } from "../../../../../entities/Metadata";
import { PpmxConnection, SourceInfo } from '../../../../../entities/common';
import { SystemIcon } from '../../../../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons';
import { buildTaskPageUrl, navigationLabelsMap } from '../../../../common/sectionsControl/uiControls/scheduleControl/NavigationRow';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type OwnProps = IListViewFieldColumn<ProjectInfo>;
type Props = OwnProps & RouteComponentProps<{}>;

const Tasks = (props: Props) => {
    const { field, entity, location } = props;

    if (!field) {
        return <span>Invalid column configuration</span>;
    }

    const taskSources = React.useMemo(() =>
        SourceInfo.getSources([PpmxConnection, ...entity.sourceInfos]).filter(_ => SourceType_.isTaskViewable(_)),
        [entity.sourceInfos]);

    return <div className="entityName clickable overflow-text font-14">
        {taskSources.map(_ => <SystemIcon
            key={_.type}
            iconName={SourceType_.getIconName(_.type)}
            title={navigationLabelsMap[_.type]}
            url={buildTaskPageUrl(entity, _, location)} />)}
    </div>;
}

export function getValue(entity: ProjectInfo, field: Field): string[] {
    const taskSources = SourceInfo.getSources([PpmxConnection, ...entity.sourceInfos]).filter(_ => SourceType_.isTaskViewable(_));
    return taskSources.map(_ => SourceTypeMap[_.type]);
}

export function getSearchValue(entity: ProjectInfo, field: Field) {
    return getValue(entity, field);
}

export default withRouter<OwnProps>(Tasks);