import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import { CheckboxVisibility, IColumn, IObjectWithKey, DefaultButton, IContextualMenuItem } from 'office-ui-fabric-react';
import { IExtensibleEntity, Dictionary, Quantization, ITimeframe, SortDirection } from '../../../entities/common';
import { SortService } from "../../../services/SortService";
import { IOrderBy } from '../../../store/views';
import { buildEntityColumn, IListProps } from './EntityDetailsList';
import TimelineList, { IRow, Styling, TimelineChange, TimelineGroupingProps } from '../timeline/TimelineList';
import { arraysEqual, notEmpty } from '../../utils/common';
import DragWarningDialog from '../DragWarningDialog';
import { ResizeEnable } from 'react-rnd';
import { ITimelineSegment } from '../timeline/TimelineSegment';
import { ITimelineMarker } from '../timeline/TimelineMarker';
import { ScaleRenderMode } from '../timeline/TimelineBody';
import { ResultsNotFoundPlaceholder, SearchResultsNotFoundPlaceholder } from '../sectionsControl/SectionPlaceholder';
import { ITimelineRelation } from '../timeline/TimelineRelation';
import { ListUserSettings, useTimelineSaver } from '../../../store/services/viewSaver';
import { HierarchyProps, THierarchyEntity, useHierarchy, chainMerger } from '../../utils/SectionHierarchyManager';

export const EntityRowMenuColumnKey = 'row-menu-column';
export const MAX_ROWS_COUNT_FOR_CORRECT_DND = 100;

export interface ITimelineProps {
    buildTree?: boolean;
    showTreeExpandColumn?: boolean;
    expandedEntitiesIds?: string[];
    primaryOutlineLevel?: number;
    initialTimeframe?: Partial<ITimeframe>;
    userTimeframe?: Partial<ITimeframe>;
    userQuantization?: Quantization;
    scaleRenderMode?: ScaleRenderMode;
    scaleMultiplier?: number;
    onScaleChange?: (timelineChange: TimelineChange) => void;
    buildRow: (entity: IExtensibleEntity) => IRow;
    renderSegmentContent?: (row: IRow, segment: ITimelineSegment) => JSX.Element | undefined;
    renderSegmentTooltipContent?: (row: IRow, segment: ITimelineSegment) => JSX.Element | undefined;
    renderMarkerTooltipContent?: (row: IRow, marker: ITimelineMarker) => JSX.Element | undefined;
    renderHeaderCellContent?: (column: IColumn) => JSX.Element | undefined;
    onSegmentClick?: (row: IRow, segment: ITimelineSegment) => void;
    onMarkerClick?: (row: IRow, marker: ITimelineMarker) => void;
    onSegmentChange?: (row: IRow, segment: ITimelineSegment, delta: Partial<ITimelineSegment>) => void;
    onMarkerChange?: (row: IRow, marker: ITimelineMarker, delta: Partial<ITimelineMarker>) => void;
    segmentDragAxis?: (row: IRow, segment: ITimelineSegment) => "x" | "y" | "both" | "none" | undefined;
    segmentEnableResizing?: (row: IRow, segment: ITimelineSegment) => ResizeEnable | undefined;
    markerDragAxis?: (row: IRow, marker: ITimelineMarker) => "x" | "y" | "both" | "none" | undefined;
    timelineGrouping?: TimelineGroupingProps;
    extractEntityForSorting?: (row: IRow, orderBy: IOrderBy | IOrderBy[] | undefined) => IExtensibleEntity | undefined;
    buildRelationCommands?: (data: ITimelineRelation) => IContextualMenuItem[] | undefined;
    styling?: Styling;
    renderTimelineHeaderFirstRow?: () => JSX.Element | undefined;
    isDayOff?: (date: Date) => boolean;        
}
type Props<T extends IExtensibleEntity = IExtensibleEntity> = IListProps<T> & ITimelineProps;

interface State {
    fieldsMap: Dictionary<Metadata.Field>;
    items: IRow[];
    orderBy?: IOrderBy | IOrderBy[];
    columns: IColumn[];
    isDragNDropAlertOpen?: boolean;
    totalCount: number;
    showCount?: number;

    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
    inEditMode?: boolean;
}

export default class EntityTimelineList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const orderBy = this.props.sorting?.orderBy;
        const fieldsMap = Metadata.toMap(props.fields, props.isFieldFake);
        this.state = {
            fieldsMap,
            items: this._buildItems(props, fieldsMap, orderBy, props.showMore?.step),
            columns: this._buildColumns(props.displayFields, fieldsMap, orderBy, props.columns),
            orderBy,
            totalCount: props.entities.length,
            showCount: props.showMore?.step
        };
    }

    //todo implement virtual timeline rows dnd and remove warning in TasksControl
    private _isDnDAvailable = (props: Props, items: IRow[]): boolean =>
        !this.state.isDragNDropAlertOpen && !!props.draggableEvents?.onDrag && items.length <= MAX_ROWS_COUNT_FOR_CORRECT_DND;

    componentWillReceiveProps(nextProps: Props) {
        let reorder = false;
        let fieldsMap = this.state.fieldsMap;
        let fieldsChanged = false;
        if (!arraysEqual(this.props.fields, nextProps.fields)) {
            fieldsChanged = true;
            fieldsMap = Metadata.toMap(nextProps.fields, nextProps.isFieldFake);
            this.setState({ fieldsMap });
        }

        let orderBy = this.state.orderBy;
        if (!SortService.areSortsEqual(this.props.sorting?.orderBy, nextProps.sorting?.orderBy)
            && !SortService.areSortsEqual(this.state.orderBy, nextProps.sorting?.orderBy)) {
            orderBy = nextProps.sorting?.orderBy;
            reorder = true;
            this.setState({ orderBy });
        }

        if (reorder
            || !arraysEqual(this.props.entities, nextProps.entities)
            || this.props.groups !== nextProps.groups
            || (nextProps.buildTree && this.props.buildRow !== nextProps.buildRow)
            || this.props.isVirtualizationDisabled !== nextProps.isVirtualizationDisabled) {
            const showCount = !nextProps.showMore
                ? undefined
                : this.state.showCount == null ? nextProps.showMore?.step : this.state.showCount;
            const items = this._buildItems(nextProps, fieldsMap, orderBy, showCount);

            this.setState({
                items,
                showCount,
                totalCount: nextProps.entities.length,
            });
        }

        if (fieldsChanged || reorder || !arraysEqual(this.props.displayFields, nextProps.displayFields) || !arraysEqual(this.props.columns, nextProps.columns)) {
            this.setState({ columns: this._buildColumns(nextProps.displayFields, fieldsMap, orderBy, nextProps.columns) });
        }
    }

    public render() {
        const { showMore, groups, userQuantization, userTimeframe, isFilterApplied, isSearchApplied } = this.props;
        const { items, showCount, totalCount, quantization, timeframe } = this.state;
        if (!items.length && isSearchApplied) {
            this.props.selection?.setAllSelected(false);
            return <SearchResultsNotFoundPlaceholder />
        }
        if (!items.length && isFilterApplied !== false) {
            this.props.selection?.setAllSelected(false);
            return <ResultsNotFoundPlaceholder />;
        }
        return (
            <>
                <TimelineList
                    buildTree={this.props.buildTree}
                    showTreeExpandColumn={this.props.showTreeExpandColumn}
                    expandedEntitiesIds={this.props.expandedEntitiesIds}
                    primaryOutlineLevel={this.props.primaryOutlineLevel}
                    items={items}
                    columns={this.state.columns}
                    onColumnHeaderClick={this._onColumnHeaderClick}
                    initialTimeframe={this.props.initialTimeframe}
                    userQuantization={userQuantization || quantization}
                    userTimeframe={userTimeframe || timeframe}
                    onScaleChange={this._onScaleChange}
                    scaleRenderMode={this.props.scaleRenderMode}
                    scaleMultiplier={this.props.scaleMultiplier}
                    renderSegmentContent={this.props.renderSegmentContent}
                    renderSegmentTooltipContent={this.props.renderSegmentTooltipContent}
                    renderMarkerTooltipContent={this.props.renderMarkerTooltipContent}
                    onSegmentClick={this.props.onSegmentClick}
                    onMarkerClick={this.props.onMarkerClick}
                    onSegmentChange={this.props.onSegmentChange}
                    onMarkerChange={this.props.onMarkerChange}
                    segmentDragAxis={this.props.segmentDragAxis}
                    segmentEnableResizing={this.props.segmentEnableResizing}
                    markerDragAxis={this.props.markerDragAxis}
                    renderHeaderCellContent={this.props.renderHeaderCellContent}
                    checkboxVisibility={this.props.checkboxVisibility ?? CheckboxVisibility.onHover}
                    selection={this.props.selection}
                    groups={this.props.groups}
                    grouping={this.props.timelineGrouping && this.props.listGrouping ? { ...this.props.timelineGrouping, ...this.props.listGrouping } : undefined}
                    dragDropEvents={this._isDnDAvailable(this.props, items)
                        ? {
                            start: this._onDragStart,
                            end: this._onDragEnd,
                            isDragDisabled: this.state.inEditMode || this.props.draggableEvents?.isDragDisabled != null
                                ? (row: IRow) => this.props.draggableEvents!.isDragDisabled!(row.entity)
                                : undefined
                        }
                        : undefined}
                    onColumnResize={this.props.onColumnResize}
                    isVirtualizationDisabled={this.props.isVirtualizationDisabled}
                    styling={this.props.styling}
                    buildRelationCommands={this.props.buildRelationCommands}
                    renderTimelineHeaderFirstRow={this.props.renderTimelineHeaderFirstRow}
                    isDayOff={this.props.isDayOff}
                    className={this.props.inlineEditProps?.onInlineEditComplete ? 'editable-grid' : undefined}
                />
                {
                    !groups && showMore && showCount && totalCount > showCount && <DefaultButton
                        iconProps={{ iconName: "PPMXArrowDown", className: "icon-small" }}
                        className="details-list-show-more"
                        text={showMore.title}
                        onClick={() => this._showMore(showCount + showMore.step)} />
                }
                {
                    this.state.isDragNDropAlertOpen &&
                        <DragWarningDialog onDismiss={() => this.setState({ isDragNDropAlertOpen: false })} onEnable={this._enableDragNDrop} />
                }
            </>
        );
    }

    private _onScaleChange = (timelineChange: TimelineChange) => {
        const origin = timelineChange.origin;
        origin && this.setState({ quantization: origin.quantization, timeframe: origin.timeframe });
        this.props.onScaleChange?.(timelineChange);
    }

    private _buildColumns(displayFields: string[], fieldsMap: Dictionary<Metadata.Field>, orderBy?: IOrderBy | IOrderBy[], userColumns?: Metadata.ISubViewColumn[]): IColumn[] {
        return [
            ...displayFields.map<IColumn | null>((_) => 
                buildEntityColumn(_, true, this.props.entityType, this.props.disableNavigation, this.props.isArchived,
                    fieldsMap, orderBy, userColumns,
                    this.props.inlineEditProps, this.props.onItemMenuRender, this.props.onItemRender, undefined, this._onInlineModeChanged)
            ),
            ...this.props.extraColumns || []
        ].filter(notEmpty);
    }

    private _onInlineModeChanged = (editMode: boolean): void => {
        this.setState({ inEditMode: editMode });
    }

    private _onColumnHeaderClick = (column: IColumn): void => {
        if (column.fieldName === undefined) return;
        const field = this.state.fieldsMap[column.fieldName];
        if (this.props.sorting?.disabled || !field || !SortService.isSortable(field, this.props.isFieldFake)) return;

        const orderBy: IOrderBy = {
            fieldName: field.name,
            direction: (column.isSorted && column.isSortedDescending) ? SortDirection.ASC : SortDirection.DESC
        };

        if (!this.props.sorting?.external) {
            this.setState({
                items: this._sort(this.state.items, orderBy, this.state.fieldsMap, this.props.isFieldFake),
                orderBy,
                columns: this._buildColumns(this.props.displayFields, this.state.fieldsMap, orderBy, this.props.columns)
            }, () => this.props.sorting?.onChange?.(orderBy));
        }
        else {
            this.props.sorting?.onChange?.(orderBy);
        }
    }

    private _buildItems(props: Props, fields: Dictionary<Metadata.Field>, orderBy?: IOrderBy | IOrderBy[], showCount?: number): IRow[] {
        const { entities, showMore, buildRow, sorting, groups, listGrouping, isFieldFake, getKey = this._getKey } = props;

        let sortedItems: IRow[] = [];
        for (const entity of entities) {
            const key = getKey(entity);
            (entity as IObjectWithKey).key = key;
            const row = buildRow(entity);
            row.key = key;
            sortedItems.push(row);
        }

        if (!sorting?.external) {
            if (groups && listGrouping) {
                const groupMap: Dictionary<IRow[]> = {};
                sortedItems.forEach(_ => {
                    const groupKey = listGrouping.getGroupKey(_.entity);
                    const array = groupMap[groupKey] || [];
                    array.push(_);
                    groupMap[groupKey] = array;
                });

                sortedItems = [];
                groups.forEach(_ => {
                    const toSort = groupMap[_.key] || [];
                    const sorted = this._sort(toSort, orderBy, fields, isFieldFake);
                    sortedItems = [...sortedItems, ...sorted];
                });
            }
            else {
                sortedItems = this._sort(sortedItems, orderBy, fields, isFieldFake);
            }
        }

        const count = showCount && (showCount + (showMore?.extraCount || 0));
        return !groups && count
            ? !orderBy && showMore?.sliceFunc
                ? showMore?.sliceFunc(sortedItems, count, _ => _.entity)
                : sortedItems.slice(0, count)
            : sortedItems;
    }

    private _getKey = (entity: IExtensibleEntity) => {
        return entity.id;
    }

    private _sort(items: IRow[], orderBy: IOrderBy | IOrderBy[] | undefined, fieldsMap: Dictionary<Metadata.Field>, isFieldFake?: (field: Metadata.Field) => boolean): IRow[] {
        if (!items.length || !orderBy)
            return items;

        const map: Dictionary<IRow> = {};
        items.forEach(_ => map[_.entity.id] = {
            ..._,
            subItems: _.subItems
                ? this._sort(_.subItems, orderBy, fieldsMap)
                : undefined
        });
        const entities = items.map(_ => this.props.extractEntityForSorting?.(_, orderBy) ?? _.entity).sort(SortService.getComparer(fieldsMap, orderBy, isFieldFake));
        return entities.map(_ => map[_.id]);
    }

    private _onDragEnd = (row: IRow, entityGroupKey?: string, insertBeforeRow?: IRow) => {
        if (this.state.isDragNDropAlertOpen) {
            return;
        }
        this.props.draggableEvents?.onDrag?.([row.entity.id], entityGroupKey as string, insertBeforeRow?.entity.id)
    }

    private _onDragStart = (row: IRow) => {
        if (this.state.inEditMode) {
            return;
        }
        if (this.state.orderBy) {
            this.setState({ isDragNDropAlertOpen: true });
            return;
        }
        this.props.draggableEvents?.onDragStart?.(row.entity);
    }

    private _enableDragNDrop = () => {
        const orderBy = undefined;
        this.setState({
            isDragNDropAlertOpen: false,
            orderBy,
            columns: this._buildColumns(this.props.displayFields, this.state.fieldsMap, orderBy, this.props.columns),
            items: this._buildItems(this.props, this.state.fieldsMap, orderBy, this.state.showCount)
        }, () => this.props.sorting?.onChange?.(orderBy));
    }

    private _showMore = (showCount: number) => {
        this.setState({
            items: this._buildItems(this.props, this.state.fieldsMap, this.state.orderBy, showCount),
            showCount: showCount
        });
    }
}

export type HierarchTimelineProps<T extends THierarchyEntity> = Omit<Props<T>, "entities"> & ListUserSettings & HierarchyProps<T>;

export const HierarchyTimelineList = <T extends THierarchyEntity>(props: HierarchTimelineProps<T>) => {
    const timelineProps = chainMerger(props,
        _ => useTimelineSaver(_),
        _ => useHierarchy(_.hierarchy, _.sorting!, _.comparerBuilder, _.onItemRender));

    return <EntityTimelineList {...timelineProps} />;
}

export const PersistTimelineList = (props: Props & ListUserSettings) => {
    const saverProps = useTimelineSaver(props);
    return <EntityTimelineList {...props} {...saverProps} />;
}