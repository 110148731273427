import * as React from 'react';
import { RouteComponentProps } from "react-router-dom";
import {
    IColumn, IconButton, DirectionalHint, Selection, IObjectWithKey, CheckboxVisibility, DetailsListLayoutMode,
    ScrollbarVisibility, ScrollablePane, IDetailsHeaderProps, IRenderFunction, ITooltipHostProps, TooltipHost, StickyPositionType,
    Sticky, IContextualMenuItem, IDialogContentProps, ContextualMenuItemType, PrimaryButton
} from 'office-ui-fabric-react';
import { StrategicPriority, actionCreators } from "../../store/StrategicPrioritiesListStore";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import DetailsListWrapper from "../common/DetailsListWrapper";
import { ViewService, IListViewColumn } from "../../services/ViewService";
import Spinner from "../common/Spinner";
import { SortService } from '../../services/SortService';
import { UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import StrategicPriorityEdit from './StrategicPriorityEdit';
import * as Metadata from '../../entities/Metadata';
import RemoveDialog from '../common/RemoveDialog';
import { TextFormatter } from '../common/formatters/TextFormatter';
import { getItemsFromSelection } from '../common/SubentitiesList';
import { IDeletionResult } from '../../store/services/storeHelper';
import { EntityType, SortDirection, updateCheckboxOptions } from '../../entities/common';
import { ClearFilterComponent } from '../common/sectionsControl/SectionPlaceholder';
import EmptyEntitiesScreen from '../common/EmptyEntitiesScreen';
import { RowMenuColumn } from '../common/extensibleEntity/RowMenuColumn';
import { RowMenuContainer } from '../common/extensibleEntity/RowMenuContainer';
import { notUndefined, toDictionaryById } from '../utils/common';
import SelectionModeSwitchableCommandBar from '../common/SelectionModeSwitchableCommandBar';
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import { SearchBox, SearchValue } from '../common/SearchBox';
import { SearchFieldService } from '../common/SearchFieldService';

type StateProps = {
    strategicPriorities: StrategicPriority[];
    currentUser: UserState;
    isLoading: boolean;
    deletionResult: IDeletionResult[] | undefined;
};

type StrategicPrioritiesListProps = typeof actionCreators & StateProps & RouteComponentProps<{}>;

type SortBy = {
    fieldName: keyof StrategicPriority;
    direction: SortDirection;
}

type StrategicPrioritiesListState = {
    selectedCount: number;
    selectedPriority: StrategicPriority | null;
    sortBy: SortBy;
    strategicPriorities: StrategicPriority[];
    columns: IColumn[];
    canManage: boolean;
    isCreateDialogOpen: boolean;
    itemsToDelete: string[];

    activePreFilter: Metadata.PreFilterOption<StrategicPriority>;
    search?: SearchValue;
}

interface IStrategicPriorityColumn extends IColumn { fieldName: keyof StrategicPriority; }

class StrategicPrioritiesList extends React.Component<StrategicPrioritiesListProps, StrategicPrioritiesListState> {
    private _selection: Selection;
    private _preFilterOptions: IContextualMenuItem[];

    constructor(props: StrategicPrioritiesListProps) {
        super(props);
        const sortBy: SortBy = {
            fieldName: "name",
            direction: SortDirection.ASC
        };

        const preFilterOptions: Metadata.PreFilterOption<StrategicPriority>[] = [
            { key: "all", name: "All", predicate: _ => true },
            { key: "active", name: "Active", predicate: _ => _.isActive },
            { key: "inactive", name: "Inactive", predicate: _ => !_.isActive }
        ];

        const canManage = contains(props.currentUser.permissions.common, CommonOperations.PrioritizationManage);
        this.state = {
            selectedCount: 0,
            selectedPriority: null,
            sortBy: sortBy,
            strategicPriorities: props.strategicPriorities,
            columns: this._buildColumns(sortBy),
            canManage: canManage,
            isCreateDialogOpen: false,
            activePreFilter: preFilterOptions[0],
            itemsToDelete: []
        };
        this._preFilterOptions = [
            { key: 'default-filters', text: 'Default filters', itemType: ContextualMenuItemType.Header },
            ...preFilterOptions.map(_ => ({
                key: _.key,
                text: _.name,
                onClick: () => this.setState({ activePreFilter: _ })
            }))
        ];
        this._selection = new Selection({
            onSelectionChanged: () => this.setState({
                selectedCount: this._selection.getSelectedCount()
            }),
            getKey: (_: IObjectWithKey) => (_ as StrategicPriority).id
        });
    }

    componentWillMount() {
        this.props.loadStrategicPriorities();
    }

    componentWillReceiveProps(props: StrategicPrioritiesListProps) {
        if (this.props.strategicPriorities !== props.strategicPriorities) {
            this.setState({ strategicPriorities: props.strategicPriorities.sort(this._getComparer(this.state.sortBy)) })
        }
    }

    public render() {
        const { deletionResult, isLoading } = this.props;
        const { selectedCount, selectedPriority, columns, canManage, itemsToDelete } = this.state;

        const selectionModeCommands = this._getSelectionModeCommands();
        return <>
            {this.state.isCreateDialogOpen && <StrategicPriorityEdit key="strategic-priority-create" onDismiss={() => this.setState({ isCreateDialogOpen: false })} />}
            {this.state.strategicPriorities.length === 0
                ? <EmptyEntitiesScreen
                    className="strategicPriority"
                    title="strategic Priorities"
                    description="Indicate what is critical or important in your organizational strategy and decide how strategic priorities impact each portfolio,
                        program, project or idea">
                    <PrimaryButton disabled={!canManage} text="Create Strategic Priority" onClick={() => this.setState({ isCreateDialogOpen: true })} />
                </EmptyEntitiesScreen>
                : <div className="strategic-priorities">
                    <h2>Strategic Priorities</h2>
                    <div className="entities-list">
                        {<div className="entities-list-header">
                            <SelectionModeSwitchableCommandBar
                                items={this._getCommands()}
                                farItems={this._getFarCommands()}
                                selectionMode={{
                                    enabled: selectedCount > 0,
                                    items: selectionModeCommands,
                                    onCancel: () => this._selection.setAllSelected(false),
                                    selectedCount,
                                }}
                            />
                        </div>}
                        {
                            isLoading
                                ? <Spinner key='spinner' />
                                : <div key='list' className="entities-list-body list-container">
                                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                        <ClearFilterComponent
                                            items={this.state.strategicPriorities}
                                            filteredItems={this.applyFilter(this.state.strategicPriorities)}
                                            search={this.state.search}
                                            onClearFilter={() => {
                                                this.setState({ activePreFilter: { key: "all", name: "All", predicate: _ => true } })
                                            }}>
                                            <DetailsListWrapper
                                                layoutMode={DetailsListLayoutMode.justified}
                                                setKey='set'
                                                items={this.applyFilter(this.state.strategicPriorities)}
                                                columns={columns}
                                                selection={this._selection}
                                                onRenderDetailsHeader={this._onRenderDetailsHeader}
                                                onColumnHeaderClick={this._onColumnHeaderClick}
                                                {...updateCheckboxOptions({
                                                    checkboxVisibility: canManage ? CheckboxVisibility.always : CheckboxVisibility.hidden
                                                }, selectionModeCommands, true)}
                                            />
                                        </ClearFilterComponent>
                                    </ScrollablePane>
                                </div>
                        }
                    </div>
                    {selectedPriority && <StrategicPriorityEdit
                        readOnly={!canManage}
                        onDismiss={() => this.setState({ selectedPriority: null })}
                        onDelete={(id: string) => this.setState({ itemsToDelete: [id] })}
                        strategicPriority={selectedPriority} >
                        {!!itemsToDelete?.length && this._renderRemoveDialog()}
                    </StrategicPriorityEdit>}
                    {!!itemsToDelete.length && !selectedPriority && this._renderRemoveDialog()}
                    {deletionResult && <RemoveDialog
                        onClose={() => this.props.dismissDeletionResult()}
                        confirmButtonProps={{ text: "Got it" }}
                        modalProps={{ styles: { main: { minWidth: 500 } } }}
                        dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                    </RemoveDialog>}
                </div>}
        </>
    }

    private _getCommands(): IContextualMenuItem[] {
        const { canManage } = this.state;
        return [
            {
                key: 'new',
                text: 'New',
                disabled: !canManage,
                iconProps: { iconName: "Add" },
                onClick: () => this.setState({ isCreateDialogOpen: true }),
            },
        ];
    }

    private fieldsToSearch: Metadata.Field[] = [
        { id: 'name', name: 'name', label: "Name", type: Metadata.FieldType.Text } as any as Metadata.Field,
        { id: 'description', name: 'description', label: "Description", type: Metadata.FieldType.Text } as any as Metadata.Field,
    ];
    
    private _getFarCommands(): IContextualMenuItem[] {
        const { activePreFilter, search } = this.state;
        return [

            {
                key: 'search',
                onRender: () => <SearchBox
                    viewColumns={this.fieldsToSearch.map(_ => _.id)}
                    fieldsMap={toDictionaryById(this.fieldsToSearch)}
                    value={search}
                    onSearch={(value: any) => this.setState({ search: value })}
                    entityType={EntityType.StrategicPriority}
                />
            },
            {
                key: 'filter',
                className: "dropdown-button",
                iconProps: { iconName: 'Filter' },
                text: activePreFilter.name,
                subMenuProps: { items: this._preFilterOptions },
            }
        ]
    }
    
    private _getSelectionModeCommands(): IContextualMenuItem[] {
        const { canManage } = this.state;
        return [
            canManage ? {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.StrategicPriority),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                onClick: () => {
                    const itemsToDelete = this._selection.getSelection().map(_ => (_ as StrategicPriority).id);
                    this.setState({ itemsToDelete });
                }
            } : undefined,
        ].filter(notUndefined);
    }

    private _renderRemoveDialog() {
        return <RemoveDialog
            onClose={() => this.setState({ itemsToDelete: [] })}
            onComplete={() => {
                this._onRemove();
                this.setState({ itemsToDelete: [], selectedPriority: null });
            }}
            confirmButtonProps={{ text: "Delete" }}
            dialogContentProps={this._getRemoveDialogContent()} />;
    }

    private _onRemove = () => {
        let selectedItems: string[] = this._selection.getSelection().map(_ => (_ as StrategicPriority).id);
        if (selectedItems.length) {
            this.props.removeStrategicPriorities(selectedItems);
        }
        this._selection.setAllSelected(false);
    }

    private _getRemoveDialogContent = () => {
        const selectedItems = getItemsFromSelection<StrategicPriority>(this._selection, this.state.itemsToDelete);
        return selectedItems.length === 1
            ? {
                title: 'Delete strategic priority',
                subText: `Are you sure you want to delete strategic priority "${selectedItems[0].name}"?`
            }
            : {
                title: 'Delete strategic priorities',
                subText: `Are you sure you want to delete selected strategic priorities (${selectedItems.length} items)?`
            };
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1 && !deletionResult[0].isDeleted) {
            return {
                title: "Unable to delete strategic priority",
                subText: `Please check if you have necessary permissions.`
            };
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return deleted.length === 1
            ? {
                title: "Strategic priority deletion is complete",
                subText: `Strategic priority "${deleted[0].name}" was deleted successfully.`
            }
            : deleted.length
                ? {
                    title: "Strategic priorities deletion is complete",
                    subText: `Selected strategic priorities (${deleted.length} items) were deleted successfully.`
                }
                : {
                    title: "Strategic priorities deletion failed"
                };
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private applyFilter = (_: StrategicPriority[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isItemVisible = (item: StrategicPriority): boolean => {
        const { activePreFilter, search } = this.state;

        if (search?.searchText && !SearchFieldService.searchInProperties(search, item)) {
            return false;
        }

        if (activePreFilter && !activePreFilter.predicate(item)) {
            return false;
        }

        return true;
    }

    private _onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IStrategicPriorityColumn) => {
        if (!column) return;
        const { fieldName, direction } = this.state.sortBy;
        const sortBy = column.fieldName === fieldName
            ? {
                fieldName: fieldName,
                direction: direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            }
            : {
                fieldName: column.fieldName,
                direction: SortDirection.ASC
            }

        this.setState({
            sortBy: sortBy,
            strategicPriorities: this.state.strategicPriorities.map(_ => _).sort(this._getComparer(sortBy)),
            columns: this._buildColumns(sortBy)
        });
    }

    private _getComparer(sortBy: SortBy) {
        const { fieldName, direction } = sortBy;
        const isDesc = direction == SortDirection.DESC;
        return (a: any, b: any) => SortService.compareWithUndefined(a, b, isDesc,
            (aF: any, bF: any) => {
                const aa = typeof aF[fieldName] === "string" ? aF[fieldName].toLowerCase() : aF[fieldName];
                const bb = typeof bF[fieldName] === "string" ? bF[fieldName].toLowerCase() : bF[fieldName];
                return (aa == bb
                    ? 0
                    : ((isDesc ? (aa > bb) : (aa < bb)) ? -1 : 1));
            });
    }

    private _buildColumns = (sortBy: SortBy): IColumn[] => {
        return [
            {
                key: "name",
                fieldName: "name",
                iconName: "PPMXStrategicPriorities",
                name: "Name",
                headerClassName: "with-icon",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) => {
                    let component = ViewService.createListColumn<IListViewColumn<StrategicPriority>>('strategicPriority/Name');
                    return <RowMenuColumn
                        onItemMenuRender={() => this._renderMenu(item)}>
                        {React.createElement(component, { entity: item, onClick: () => this.setState({ selectedPriority: item }) })}
                    </RowMenuColumn>
                }
            },
            {
                key: "importance",
                fieldName: "importance",
                name: "Importance",
                minWidth: 250,
                maxWidth: 350,
                isResizable: true,
                onRender: (item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) => {
                    let component = ViewService.createListColumn<IListViewColumn<StrategicPriority>>('strategicPriority/Importance');
                    return React.createElement(component, { entity: item });
                }
            },
            {
                key: "isActive",
                fieldName: "isActive",
                name: "Status",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onRender: (item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) => {
                    let component = ViewService.createListColumn<IListViewColumn<StrategicPriority>>('strategicPriority/IsActive');
                    return React.createElement(component, { entity: item });
                }
            },
            {
                key: "description",
                fieldName: "description",
                name: "Description",
                minWidth: 250,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) =>
                    <div className="strategic-priority-description" title={item.description}>
                        <TextFormatter isMultiline value={item.description} />
                    </div>
            }
        ].map(_ => ({
            ..._,
            isSorted: _.fieldName == sortBy.fieldName,
            isSortedDescending: _.fieldName == sortBy.fieldName && sortBy.direction == SortDirection.DESC
        }));
    }

    private _renderMenu = (strategicPriority: StrategicPriority): JSX.Element => {
        const items: IContextualMenuItem[] = this.state.canManage
            ? [
                {
                    key: "edit",
                    name: "Edit",
                    iconProps: { iconName: 'Edit' },
                    onClick: () => { this.setState({ selectedPriority: strategicPriority }); }
                },
                {
                    key: 'toggle-activate',
                    name: strategicPriority.isActive ? 'Deactivate' : 'Activate',
                    iconProps: { iconName: strategicPriority.isActive ? 'Archive' : 'Refresh' },
                    onClick: () => { this._togglePriority(strategicPriority); }
                },
                {
                    key: 'delete',
                    name: 'Delete',
                    style: { color: 'red' },
                    iconProps: { iconName: 'Delete', style: { color: 'red' } },
                    onClick: () => { this.setState({ itemsToDelete: [strategicPriority.id] }) }
                }
            ]
            : [
                {
                    key: "view",
                    name: "View",
                    iconProps: { iconName: 'View' },
                    onClick: () => { this.setState({ selectedPriority: strategicPriority }); }
                }
            ];

        return <RowMenuContainer className="menu">
            <IconButton
                menuIconProps={{ iconName: 'More' }}
                menuProps={{
                    directionalHint: DirectionalHint.bottomRightEdge,
                    items: items
                }} />
        </RowMenuContainer>;
    }

    private _togglePriority = (priority: StrategicPriority) => {
        priority.isActive = !priority.isActive;
        this.props.saveStrategicPriority(priority);
    }

    private _getSelected() {
        return this._selection.getSelection().map(_ => _ as StrategicPriority);
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    const byId = state.strategicPriorities.byId;
    return {
        strategicPriorities: state.strategicPriorities.allIds.map(_ => byId[_]),
        currentUser: state.user,
        isLoading: state.strategicPriorities.isLoading,
        deletionResult: state.strategicPriorities.deletionResult
    }
}

export default connect(mapStateToProps, { ...actionCreators })(StrategicPrioritiesList);