import * as React from 'react';
import { IExtensibleEntity } from '../../entities/common';
import { SearchValue } from './SearchBox';
import { SearchFieldService } from './SearchFieldService';

export type SearchConfigurationData = {
    search?: SearchValue;
    onSearch: (value: SearchValue) => void;
}

export type SearchData<T> = SearchConfigurationData & {
    isItemVisible: (item: T) => boolean;
}

export type IWithSearch = {
    search?: SearchData<IExtensibleEntity>;
}

export const withSearch = <T extends IExtensibleEntity, TProps extends {}>(
    Component: React.ComponentType<TProps & IWithSearch>) => {
    return (props: Omit<TProps, keyof IWithSearch>) => {
        const [search, onSearch] = React.useState<SearchValue>();

        const isItemVisible = React.useCallback((item: T) => SearchFieldService.isItemVisible(search, item), [search]);

        const searchProps = {
            search,
            onSearch,
            isItemVisible
        };

        return <>
            {<Component
                {...props as any as TProps}
                search={searchProps}
            />}
        </>
    }
}

export const isSearchChanged = <T,>(a: IWithSearch, b: IWithSearch) => {
    if (a === b) {
        return false;
    }

    return a.search?.search !== b.search?.search;
}