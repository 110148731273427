import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { IObjectiveAttrs, ObjectiveOrKeyResult, OKRValueType } from '../../../../../store/ObjectivesListStore';
import { nameof } from '../../../../../store/services/metadataService';
import { formatOKRValue, OKRValueFormatter } from '../../../../common/formatters/OkrFormatters';
import { Field } from '../../../../../entities/Metadata';

const Value = (props: IListViewFieldColumn<ObjectiveOrKeyResult>) =>
    <OKRValueFormatter
        value={props.entity.attributes[props.field.name]}
        type={props.entity.attributes.ValueType}
        hidden={props.entity.attributes.ValueType === OKRValueType.Flag && props.field.name !== nameof<IObjectiveAttrs>("CurrentValue")}
    />
export default Value;

export const getSearchValue = (item: ObjectiveOrKeyResult, field: Field) => {
    const hidden = item.attributes.ValueType === OKRValueType.Flag && field.name !== nameof<IObjectiveAttrs>("CurrentValue");
    return hidden ? undefined : formatOKRValue(item.attributes[field.name], item.attributes.ValueType);
}