import * as React from 'react';
import { IListViewColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { Portfolio as PortfolioModel } from "../../../../../store/PortfoliosListStore";
import { Program } from '../../../../../store/ProgramsListStore';
import EntityName from '../EntityName';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { Dictionary } from '../../../../../entities/common';
import { getPortfolios } from '../../../../../store/project/utils';
import { PPMFeatures, Subscription } from '../../../../../store/Tenant';

interface StateProps {
    portfolios: Dictionary<PortfolioModel>;
    subscription: Subscription;
};

const Portfolio = (props: IListViewColumn<ProjectInfo | Program> & StateProps) => {
    const { entity, isTimelineView, portfolios, subscription } = props;
    const infos = getPortfolios(entity);
    const withDates = infos.length === 1;
    const onlyIcon = infos.length > 1;
    const isAccessible = Subscription.contains(subscription, PPMFeatures.PortfolioManagement);
    return <div className="icons-box">
        {
            infos.map(portfolioInfo => <EntityName
                key={portfolioInfo.id}
                name={portfolioInfo.name}
                startDate={portfolios[portfolioInfo.id]?.attributes.StartDate}
                finishDate={portfolios[portfolioInfo.id]?.attributes.FinishDate}
                withDates={withDates}
                onlyIcon={onlyIcon}
                isTimelineView={isTimelineView}
                url={isAccessible ? `/portfolio/${portfolioInfo.id}` : undefined}
                imageId={portfolios[portfolioInfo.id]?.imageId}
                imageClassName='pf-logo'
            />)
        }
    </div>;
}
export function getSearchValue(entity: ProjectInfo | Program) {
    return getPortfolios(entity);
}

function mapStateToProps(state: ApplicationState) {
    return {
        portfolios: state.portfolios.byId,
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps, undefined)(Portfolio);