import * as React from 'react';
import { IListViewColumn } from "../../../../../services/ViewService";
import { Program } from "../../../../../store/ProgramsListStore";
import { getProjectsMetrics } from "../../../../../store/program/utils";
import { toPercentString } from "../../../../utils/common";

export default class PercentCompleted extends React.Component<IListViewColumn<Program>> {
    public render() {
        const entity = this.props.entity;
        return <span className="font-14">
            {toPercentString(PercentCompleted.getValue(entity))}
        </span>;
    }

    public static getValue(entity: Program): number {
        const metrics = getProjectsMetrics(entity.calculation);
        return metrics.total !== 0 ? metrics.completed / metrics.total : 0;
    }

    public static getSearchValue(entity: Program): string | undefined {
        if (!entity.calculation.projectProgresses) {
            return undefined;
        }
        return toPercentString(PercentCompleted.getValue(entity));
    }
}