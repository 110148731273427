import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { extractKeyResult, isObjective, ObjectiveCalculationType, ObjectiveOrKeyResult, OKRDirectionOptions, OKRValueType } from '../../../../../store/ObjectivesListStore';
import * as Metadata from "../../../../../entities/Metadata";
import { DropdownFormatter } from '../../../../common/formatters/DropdownFormatter';

const Direction = (props: IListViewFieldColumn<ObjectiveOrKeyResult>) => {
    if (isObjective(props.entity)
        && props.entity.attributes.CalculationType === ObjectiveCalculationType.Calculated
        && props.entity.attributes.ValueType !== OKRValueType.Flag) {
        return <DropdownFormatter {...props} options={Metadata.getOptions(props.field)} value={props.entity.attributes.Direction} />
    }

    const keyResult = extractKeyResult(props.entity);
    if (keyResult && keyResult.calculationType === ObjectiveCalculationType.Calculated && keyResult.valueType !== OKRValueType.Flag) {
        return <DropdownFormatter {...props} options={OKRDirectionOptions} value={keyResult.direction} />;
    }

    return <></>;
}
export default Direction;

export const getSearchValue = (item: ObjectiveOrKeyResult) => {
    if (item.attributes.CalculationType === ObjectiveCalculationType.Calculated
        && item.attributes.ValueType !== OKRValueType.Flag) {
        return item.attributes.Direction;
    }
    return undefined;
}