import './SearchBox.css';
import * as React from "react";
import { connect } from "react-redux";
import { Field } from "../../../entities/Metadata";
import { Callout, Checkbox, DefaultButton, DirectionalHint, IconButton } from "office-ui-fabric-react";
import { arraysEqual, notUndefined } from '../../utils/common';
import { Dictionary, EntityType } from '../../../entities/common';
import { SearchFieldService } from '../SearchFieldService';
import { UserState } from '../../../store/User';
import { TenantState } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { useDidMountEffect } from '../../utils/effects';

export type SearchValue = {
    searchText: string;
    searchFields: Field[];
    isFieldFake?: (field: Field) => boolean;
}

type OwnProps = {
    value?: SearchValue;
    onSearch: (search: SearchValue) => void;
    viewColumns: string[];
    fieldsMap: Dictionary<Field>;
    isFieldFake?: (field: Field) => boolean;
    noSearchFields?: Field[];
    entityType?: EntityType;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
}

type Props = OwnProps & StateProps;

function filterFields(fieldIds: string[],
    fieldsMap: Dictionary<Field>,
    isFieldFake?: (field: Field) => boolean,
    noSearchFields?: Field[],
    user?: UserState,
    entityType?: EntityType,
    tenant?: TenantState) {
    return fieldIds.map(_ => fieldsMap[_])
        .filter(notUndefined)
        .filter(_ => SearchFieldService.canHandle(_, isFieldFake, noSearchFields, user, entityType, tenant));
}

const SearchBox = (props: Props) => {
    const [configureMode, setConfigureMode] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [editMode, setEditMode] = React.useState(props.value !== undefined);

    const filteredFields = filterFields(props.viewColumns, props.fieldsMap, props.isFieldFake, props.noSearchFields, props.user, props.entityType, props.tenant);
    const [viewFields, setViewFields] = React.useState<Field[]>(filteredFields);

    const [search, setSearch] = React.useState(props.value ?? { searchText: "", searchFields: filteredFields, isFieldFake: props.isFieldFake });

    useDidMountEffect(() => props.onSearch(search), [search])

    const onSearchTextChange = React.useCallback((value: string) => {
        setSearch({ ...search, searchText: value });
        if (value) {
            // Bug 14268: Search. Lost focus from the search field on the timeline views Task page
            setTimeout(() => {
                inputRef.current?.blur();
                inputRef.current?.focus();
            }, 0);
        }
    }, [search]);

    const changeSelectedFields = React.useCallback((newSearchFields: Field[]) => {
        setSearch({ ...search, searchFields: newSearchFields });
    }, [search]);

    const onClear = React.useCallback(() => {
        setSearch({ ...search, searchText: "" });
        inputRef.current?.focus();
    }, [search]);

    const onDismiss = React.useCallback(() => {
        onClear();
        setEditMode(false);
    }, [onClear]);

    React.useEffect(() => {
        if (!arraysEqual(filteredFields, viewFields)) {
            setViewFields(filteredFields);
            setSearch({ ...search, searchText: "", searchFields: filteredFields });
            setEditMode(false);
            return;
        }
    }, [filteredFields]);

    const onBlur = React.useCallback((ev) => {
        if (!ev.currentTarget.contains?.(ev.relatedTarget)) {
            if (!search.searchText && !configureMode) {
                setEditMode(false);
            }
            setIsActive(false);
        }
    }, [search.searchText, configureMode]);

    const onDismissConfigure = React.useCallback((ev) => {
        setConfigureMode(false);
        if (!ev.currentTarget.contains?.(ev.relatedTarget) && !search.searchText) {
            setEditMode(false);
        }
    }, [search.searchText]);

    const onFocusInput = React.useCallback(() => {
        if (configureMode) {
            setConfigureMode(false);
        }
    }, [configureMode]);

    const onFocus = React.useCallback(() => {
        setIsActive(true);
    }, []);

    React.useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onDismiss();
            }
            // Bug 14156: Search. The pointer jumps to the Actions button after moving the pointer to first place in the search field
            // prevent coursour leaving the search field (command bar control default behavior)
            if ((e.key === 'ArrowLeft' && inputRef.current?.selectionStart === 0) ||
                (e.key === 'ArrowRight' && inputRef.current?.selectionStart === inputRef.current?.value.length)) {
                inputRef.current?.focus();
            }
        };
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, []);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const searchRef = React.useRef<HTMLInputElement>(null);
    return <>
        {!editMode && <DefaultButton
            className={`dropdown-button filter-btn`}
            iconProps={{ iconName: 'Search' }}
            text={props.value?.searchText || "Search"}
            onClick={() => setEditMode(true)} />}
        {editMode && <div className="search-box-wrapper" ref={searchRef} onFocus={onFocus} onBlur={onBlur}>
            <div className={`search-box ${isActive ? 'is-active' : ''}`}>
                <input
                    type="text"
                    autoFocus
                    placeholder="Search"
                    ref={inputRef}
                    className="search-input"
                    value={search.searchText}
                    onFocus={onFocusInput}
                    onChange={e => onSearchTextChange(e.target.value)} />
                <IconButton
                    iconProps={{ iconName: 'PPMXGear' }}
                    title="Configure columns"
                    onClick={() => setConfigureMode(!configureMode)} />
                {search.searchText && <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    title="Clear"
                    onClick={onClear} />}
            </div>

            {configureMode && <Callout
                className="configure-columns"
                onDismiss={onDismissConfigure}
                target={searchRef.current}
                calloutWidth={300}
                isBeakVisible={false}
                gapSpace={2}
                preventDismissOnResize={true}
                directionalHint={DirectionalHint.bottomRightEdge}
                directionalHintForRTL={DirectionalHint.bottomLeftEdge}
            >
                <div className="configure-columns-header">
                    <span className="title">Columns to search</span>
                    <span>({search.searchFields.length} selected)</span>
                </div>

                <div className="configure-columns-buttons">
                    <Checkbox
                        className="select-all"
                        checked={search.searchFields.length === viewFields.length}
                        label="All columns"
                        onChange={(e, checked) => changeSelectedFields(checked ? viewFields : [])}
                    />
                    {viewFields.map(f => <Checkbox
                        key={f.id}
                        checked={search.searchFields.includes(f)}
                        label={f.label ?? f.name}
                        title={f.label ?? f.name}
                        onChange={(e, checked) => changeSelectedFields(checked ? [...search.searchFields, f] : search.searchFields.filter(c => c !== f))}
                    />)}
                </div>
            </Callout>}
        </div>}
    </>;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(SearchBox);